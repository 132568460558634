import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import ResultCard from "../components/resultComponents/ResultCard"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import ArticleImage from "../components/images/ArticleImage"
import { OutboundLink } from "gatsby-plugin-gtag"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"elisa"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Katso parhaat Elisa liittymätarjoukset 2020 - Tutustu! "
        desc="Katso täältä parhaat Elisa Saunalahti tarjoukset liittymiin. Löydät parhaat puhelinliittymät ja nettiliittymä kätevästi täältä parhaaseen tarjous hintaan. Lue lisää!"
      />
      <Container>
        <Header1>Katso parhaat Elisa liittymätarjoukset 2020</Header1>
        <TextArea>
          <b>Elisa/Saunalahti tarjoa jokaiselle juuri sopivan liittymän.</b>{" "}
          Tahdoitpa sitten rajattomasti kaikkea kiinteään kuukausihintaan tai
          maksaa vain juuri siitä mitä käytät, niin sopivan puhelinliittymän
          löydät alapuolelta.
        </TextArea>
        <Header3>Tutustu alapuolelta Elisan:n liittymiin</Header3>
        <AdserviceCompare />
        <RowContainer title={"Tutustu myös muihin operaattoreihin:"}>
          <Button link={"/dna/"} title={"Dna"} />
          <Button link={"/telia/"} title={"Telia"} />
          <Button link={"/moi/"} title={"Moi Mobiili"} />
        </RowContainer>
        <Header2>Elisa on johtava operaattori Suomessa</Header2>
        <ArticleImage imgName="elisaetusivu.jpg" />
        <TextArea>
          Puhelinoperaattori Elisa Oyj on suomalaisille tuttu nimi muun muassa
          sen lukuisista TV-mainoksista. Elisa on suosittu suomalainen
          puhelinoperaattori ja digitaalisten palveluiden markkinajohtaja, joka
          tarjoaa jokaiseen tilanteeseen sopivia liittymäpaketteja ja muita
          palveluja asiakkailleen.
        </TextArea>
        <TextArea>
          Elisa palvelee tällä hetkellä jopa yli 2,8 miljoonaa asiakasta niin
          Suomessa ja Virossa kuin myös kansainvälisestikin ja sen verkossa on
          yli 6,2 miljoonaa liittymää. Elisan voisikin sanoa olevan
          tietoliikenne- ja digitaalisten palveluiden edelläkävijä.
        </TextArea>
        <Header3>Mikä on Elisa?</Header3>
        <TextArea>
          Elisa Oyj on peräti yli 130-vuotisen matkan kulkenut nykypäivän
          pörssiyhtiö, joka palvelee kuluttajia, julkishallinnon organisaatioita
          ja yrityksiä enimmäkseen Suomen ja Viron alueelle, mutta myös
          kansainvälisesti. Elisa ei ole mikään pienimpiin kuuluva toimija,
          sillä sen henkilöstöön kuuluu noin 4 800 henkilöä ja sen liikevaihto
          oli vuonna 2018 peräti 1,83 miljardia euroa. Elisa on innovatiivinen
          ja moderni yhtiö, joka kehittää toimintojaan ja palveluitaan
          jatkuvasti sekä haastaa vallitsevia tapoja hyödyttääkseen
          asiakkaitaan.
        </TextArea>
        <Header4>Historia</Header4>
        <TextArea>
          Elisan historia on alkanut jo vuodesta 1882 kun lennätinmekaanikko sai
          luvan perustaa puhelinlaitoksen Helsinkiin. Tämän jälkeen matka
          nykyiseksi Elisaksi alkoi. Elisa laajeni tasaisesti läpi historiansa
          ja otti menestysaskeleita. Elisaan sulautunut Radiolinja esimerkiksi
          mursi posti- ja telelaitoksen monopolin, ja Elisa oli mukana Helsingin
          Olympialaisten onnistumisessa. Radiolinja, joka kuului Elisaan, kytki
          maailman ensimmäisen GSM-puhelun ja avasi ensimmäisen kaupallisen
          GSM-palvelun 1991. Elisa teki historiallisen tempauksen vuonna 2007,
          kun se avasi kaupalliseen käyttöön 3G UMTS900 -verkon.
        </TextArea>
        <Header4>Nykypäivä</Header4>
        <TextArea>
          Tänä päivänä Elisa Oyj -nimellä kulkeva yhtiö on tunnettu ennen myös
          nimillä Elisa Communications Oyj ja Helsingin Puhelin Oyj. Yhtiö on
          nykyään julkisesti noteerattu Nasdaq Helsinki Suuret Yhtiöt -listalla
          ja sillä on noin 185 000 osakkeenomistajaa. Elisa on Suomessa johtava
          kiinteän verkon operaattori ja mobiiliverkko-operaattori, jonka
          yhteistyökumppaneina toimivat muun muassa Vodafone ja Telenor.
          Elisalla Oyj:llä on tänä päivänä lisäksi myös useita tytäryhtiöitä,
          joihin kuuluvat muun muassa seuraavat yhtiöt:le työntekijöilleen
          viikon palkallisen isovanhempainvapaan.
          <ul>
            <li>Puhelinmyyntiyhtiö Enia Oy</li>
            <li>Tietoliikenneoperaattori Elisa Eesti AS</li>
            <li>Videoneuvotteluoperaattori Elisa Videra Oy</li>
            <li>IPTV-palveluyhtiö Watson Nordic Oy</li>
          </ul>
        </TextArea>
        <Header4>Perusarvot</Header4>
        <TextArea>
          Elisan perusarvoihin kuuluvat vastuullisuus, asiakaskeskeisyys,
          uusiutuminen, tuloksellisuus ja yhteistyö. Elisa pyrkiikin uusiutumaan
          ja parantamaan toimintaansa jatkuvasti, joka myös näkyy yhtiön
          historiassa edelläkävijänä ja sen roolina nykyisenä markkinajohtajana.
          Elisa etsii uusia toimintamalleja ja haastaa vanhoja uskomuksia
          luodakseen innovatiivisia ratkaisuja. Se kertoo visiokseen olla muun
          muassa paras yhteyksien luoja, erinomaisuuden esimerkki ja
          kansainvälinen digitaalisissa palveluissa. Kansainvälistä toimintaa
          yhtiöllä onkin, vaikka sen päämarkkina-alueet ovat tällä hetkellä
          Suomi ja Viro, jossa se on myös suosittu palveluntarjoaja.
        </TextArea>
        <Header3>Miksi kannattaa valita Elisa?</Header3>
        <TextArea>
          Elisan valitsemista kannattaa harkita jo yksinkertaisesti sen
          kattavien liittymien vuoksi. Elisa tarjoilee jokaisen tarpeisiin
          sopivia erilaisia liittymiä hyvinkin kilpailukykyisillä hinnoilla.
          Elisalla on niin kaiken kattavia paketteja kuin myös liittymiä, joihin
          voi ladata vain tarvitsemansa palvelut, silloin kun itse haluaa.
          Elisan liittymissä on valinnanvaraa aina kuukausittain laskutettavista
          liittymistä tarpeen vaatiessa ladattaviin prepaid-liittymiin ja
          erilaisiin rajattomaan käyttöön tarkoitettuihin paketteihin saakka.
          Lisäksi Elisan erilaisiin liittymiin ja palveluihin kuuluu useita
          ajankohtaisia ja hyödyllisiä tarjouksia.
        </TextArea>
        <Header4>Monipuoliset tuotteet</Header4>
        <TextArea>
          Kilpailukykyisten ja monipuolisten liittymien lisäksi Elisa tarjoaa
          muitakin palveluita ja tuotteita. Elisan tuotteisiin kuuluvat
          erilaiset laitteet kuten älypuhelimet, tietokoneet ja verkkolaitteet.
          Liittymien ja elektroniikan lisäksi Elisa tarjoaa innovatiivisia ja
          laadukkaita palveluita, joita ovat muun muassa ääni- ja e-kirjat sekä
          kanavakortit ja turvapalvelu, pilvitallennuspalvelu, mobiilivarmenne
          ja äly- ja turvaratkaisut kotiin. Yhtiöllä on lisäksi Elisa Viihde
          -palvelu, jossa voi vuokrata elokuvia ja katsella sarjoja tai urheilua
          usealla eri laitteella. Elisan palvelu- ja tuotetarjonta on siis hyvin
          monipuolinen.
        </TextArea>
        <Header4>Kilpailukykyiset hinnat</Header4>
        <TextArea>
          Yksi painavimmista syistä Elisan valitsemiselle ovat myös sen erittäin
          kilpailukykyiset hinnat ja tarjoukset. Elisa tarjoaa kattavia
          liittymäpaketteja hyvin kohtuullisilla hinnoilla. Lisäksi Elisan
          laitteita voi ostaa osamaksulla, joiden kuukausikulut voivat olla
          hyvinkin pienet. Elisa myy esimerkiksi älypuhelimia osamaksuilla,
          joiden kuukausierät voivat olla jopa alle viisi euroa. Kannattaa
          muistaa, että osamaksun kuukausierä kuitenkin riippuu esimerkiksi
          ostettavasta laitteesta. Kilpailukykyisten hintojen lisäksi Elisalla
          on säännöllisesti asiakkaille hyödyllisiä tarjouksia, joita voi pitää
          silmällä esimerkiksi Elisan nettisivuston kautta.
        </TextArea>
        <Header4>Vastuullisuus</Header4>
        <TextArea>
          Yhteiskuntavastuu on liittymien ja palveluiden ohella yksi syy miksi
          kannattaa suosia juuri Elisaa. Elisa on nimittäin sitoutunut Science
          Based Targets -organisaation hyväksymiin tavoitteisiin, joilla se
          vähentää hiilidioksidipäästöjään. Tavoitteet ovat tieteisiin
          perustuvia. Science Based Targets on organisaatio, jonka muodostavat
          Maailman luonnonvarainstituutti WRI, YK:n Global Compact -aloite,
          ympäristöjärjestö WWF ja ilmastoraportointiin keskittyvä CDP-järjestö.
          Vuonna 2018 hiilidioksidipäästöjen vähentämisen tavoitteeseen oli
          sitoutunut 140 yritystä maailmassa, mutta vain viisi Suomessa. Elisa
          on yksi näistä sitoutuneista.
        </TextArea>
        <Header3>Elisan liittymät</Header3>

        <TextArea>
          Elisa tarjoaa monipuolisesti erilaisia liittymiä, joista löytyy
          jokaisen tarpeisiin sopiva paketti. Elisan monipuolisten liittymien
          valikoimaan kuuluvat esimerkiksi puhelinliittymät, mobiili- ja
          hybridi- ja kiinteä laajakaista, vanhoille lankapuhelinliittymille
          tarkoitettu KotiPuhelin-liittymä, seutuverkot, Wi-Fi-puhelut ja
          prepaid-liittymät sekä muut ladattavat liittymät. Liittymien
          tiedonsiirtonopeudet, hinnat ja ominaisuudet vaihtelevat. Elisalla
          tarjotaan liittymiä ainoastaan muutaman euron maksavista kevyeen
          käyttöön tarkoitetuista liittymistä aina jokapäiväiseen aktiiviseen
          käyttöön tarkoitettuja kymmeniä euroja maksavia liittymiä myöten.
          Lisäksi Elisalla on kattavasti erilaisia liittymäpaketteja näiden
          hintaluokkien väliltä.
        </TextArea>
        <Header4>Puhelinliittymät</Header4>
        <ArticleImage imgName="elisaetusivu.jpg" />
        <TextArea>
          Elisan Saunalahti-puhelinliittymillä on erilaisia ominaisuuksia ja
          kuukausihintoja, mutta yhteistä on, että kaikki Elisan
          puhelinliittymät saa aina ilman datakattoa tai sitoutumispakkoa.
          Edullisimmasta päästä olevan puhelinliittymän voi saada ainoastaan
          7,90 euron kuukausihintaan. Sen sijaan tätä kalliimpi ja Elisan
          suosituin puhelinliittymä{" "}
          <a href="/tilaa/elisahuoletonpremium150" rel="nofollow">
            Saunalahti Huoleton Premium 150M
          </a>{" "}
          maksaa 29,90 euroa kuukaudessa, mutta tarjoaa rajattoman netin, 10
          gigatavua per kuukausi Euroopassa ja lisäksi S-ryhmän bonusta.
          Elisalla on monia muitakin liittymiä, jotka kerryttävät S-ryhmän
          bonusta.
        </TextArea>
        <Header4>Latausliittymät</Header4>
        <TextArea>
          Elisan latausliittymät ovat liittymiä, jotka ladataan etukäteen ennen
          käyttöä. Latausliittymien hyvä puoli on, että niitä käyttäessä välttää
          mahdolliset yllättävät kulut. Elisan Saunalahti Latausliittymä on
          aivan normaali matkapuhelinliittymä, jonka käytöstä maksetaan
          etukäteen. Liittymässä on mielenkiintoinen ja hyödyllinen ominaisuus
          saldon loppumisen varalle. Saldon loppuessa asiakas saa viestin, minkä
          jälkeen voi lähettää vielä 5 viestiä ja puhua vielä viisi minuuttia.
          Latauksella toimivia liittymiä ovat Elisalla myös prepaid-liittymät,
          joista maksetaan vain käytön mukaan ilman kuukausimaksuja.
        </TextArea>
        <Header4>Laajakaistaliittymät</Header4>
        <TextArea>
          Elisa tarjoaa myös erilaisia laajakaistaliittymiä asiakkailleen.
          Liittymiä on saatavilla erilaisilla kuukausihinnoilla ja sisällöillä.
          Elisa tarjoaa hyvin edullisia muutaman euron hintaisia liittymiä
          kevyeen käyttöön, mutta myös aktiiviseen käyttöön tarkoitettuja
          <a href="/mobiililaajakaista/">mobiililaajakaistaliittymiä</a>, joista
          suosittu on esimerkiksi{" "}
          <a href="/tilaa/saunalahti4gsuper" rel="nofollow">
            Saunalahti Mobiililaajakaista 4G Super
          </a>
          , joka sisältää rajattoman netin, 100 Mbit/s. Se kerryttää lisäksi
          S-ryhmän bonusta, mikäli asiakas on yhdistänyt bonuskorttinsa
          liittymään. Elisan liittymissä on myös 5G-liittymiä kuten 600 Mbit/s
          ja 20 Gt per kuukausi tarjoava{" "}
          <a href="/tilaa/saunalahti5g600">
            Saunalahti Mobiililaajakaista 5G 600M
          </a>
          .
        </TextArea>
        <Header3>Elisan tuotteiden ja palvelujen ominaisuudet</Header3>
        <TextArea>
          Innovatiivisena tekijänä Elisa on lisännyt tuotteisiinsa erilaisia
          asiakkaiden tarpeisiin sopivia lisäpalveluita ja ominaisuuksia, kuten
          esimerkiksi S-ryhmän bonuksen kerryttämisen mahdollisuuden Elisan
          palveluita käyttämällä. Useat Elisan liittymistä kerryttävät S-ryhmän
          bonusta, mikäli asiakkaalla on bonuskortti ja hän on yhdistänyt sen
          liittymäänsä. S-ryhmän bonusta saa useista Elisan puhe- ja
          mobiililaajakaistaliittymistä sekä laajakaistaliittymistä. Bonuksen
          saa kertymään aktivoimalla bonuksen ja lisäämällä S-etukortin numeron
          liittymäänsä Elisan nettisivuston OmaElisa-palvelun kautta. Elisan
          bonusta kerryttävät liittymät voi tarkastaa Elisan nettisivuston
          tuoteosiosta.
        </TextArea>
        <Header4>5G liittymät</Header4>
        <TextArea>
          Elisalla on tarjolla paljon puhuttuja 5G-liittymiä. 5G on jopa
          kymmenen kertaa nopeampi kuin 4G, tarkoittaen, että suuret tiedostot
          ovat asiakkaan käytössä hyvin nopeasti. Lisäksi pelaaminen 5G-verkossa
          on loistava kokemus, sillä 5G:n viive on minimaalinen ja sen
          luotettavuus on nykyverkkoja parempi. Elisa on avannut 5G-verkkoja jo
          useaan Suomen kaupunkiin. Myös ensimmäiset 5G-päätelaitteet,
          reitittimet ja puhelimet ilmestyvät vuoden 2020 aikana, mutta ne
          tulevat yleisemmin Elisan asiakkaiden saataville vasta vuonna 2020.
          Tiesitkö nämä tärkeät asiat Elisan 5G:n liittyen:
          <ul>
            <li>5G-verkon hyödyt saavutetaan ainoastaan 5G-päätelaitteella.</li>
            <li>
              Ensimmäiset 5G-puhelimet tulevat markkinoille loppuvuodesta 2020.
            </li>
            <li>5G-puhelinten valikoima kasvaa vuonna 2020.</li>
            <li>
              Oikeanlaisen liittymän ja laitteen lisäksi on oltava 5G-verkon
              peittoalueella.
            </li>
            <li>
              Elisan tavoite on laajentaa 5G-verkkoa joka puolelle Suomea.
            </li>
          </ul>
        </TextArea>
        <Header4>Asiakaspalvelu</Header4>
        <TextArea>
          Asiakkaiden tarpeisiin Elisa on kehittänyt myös toimivan ja
          asiakaspalvelun, jonne saa helposti yhteyden. Elisan asiakaspalvelu on
          auki sunnuntaita lukuun ottamatta joka päivä ja sinne voi ottaa
          yhteyttä puhelimitse, sähköpostitse tai live-chatin ja sosiaalisen
          median kautta. Asiakaspalveluun voi varata myös soittoajan, joka on
          kiireisessä arjessa kätevää. Elisa lupaa soittaa asiakkaalle 30
          minuutin sisällä varatusta ajasta. Elisalla on myös niin kutsuttu Oma
          Guru, joka antaa henkilökohtaista apua tietotekniikkaongelmiin joko
          puhelimitse tai jopa paikan päällä.
        </TextArea>
        <Header4>OmaElisa</Header4>
        <TextArea>
          {" "}
          Elisa helpottaa asiakkaidensa toimintaa myös OmaElisa-palvelulla, joka
          on palvelu, jonne asiakkaat voivat kirjautua käyttäjätunnuksella ja
          salasanalla. Palvelussa voi esimerkiksi nähdä laskunsa ja maksaa ne.
          Palvelussa voi myös muuttaa tietojaan, poistaa estoja ja hallita
          lisäpalveluita, kuten esimerkiksi lisätä S-ryhmän bonuskorttinsa
          liittymään bonusta kerryttämään. OmaElisa toimii netin selaimessa,
          mutta sen voi ladata myös sovelluksena iPhonelle tai
          Android-laitteelle. Oman tunnuksen palveluun voi luoda helposti Elisan
          nettisivuston kautta tunnistautumalla verkkopankkitunnuksilla tai
          mobiilivarmenteella.
        </TextArea>
        <Header3>Elisan tarjoukset</Header3>
        <Header4>Laitetarjoukset</Header4>
        <TextArea>
          Elisalla on kutakuinkin kaiken aikaa tarjolla useita hyödyllisiä
          kampanjoita, jotka voivat säästää asiakkaiden rahaa. Elisan
          tarjouksiin kuuluvat ajankohdasta riippuen esimerkiksi tuntuvat
          alennukset laitteista. Elisan tarjoussivulta voi löytää esimerkiksi
          televisioita, kannettavia tietokoneita, älypuhelimia ja muita
          laitteita jopa satojen euron alennuksella. Elisan laitteiden
          ajankohtaiset alennukset ovat toisin sanoen ehdottomasti
          kilpailukykyisiä. Puhelimia ja tietokoneita pienempiä ja edullisempia
          laitteita kuten kuulokkeita, latureita ja kelloja voi löytää
          Elisa-nettisivuston tarjousosiosta myös suuresti alennetuilla
          hinnoilla.
        </TextArea>
        <Header4>Liittymätarjoukset</Header4>
        <TextArea>
          Laitealennusten lisäksi Elisa tarjoaa useita erilaisia
          <a href="/liittymatarjous/">liittymätarjouksia</a>, jotka vaihtelevat
          ajankohdan mukaan. Yksi esimerkki on laitteen ja liittymän
          yhdistelmäkauppa, jolloin asiakas ostaa kauppaan kuuluvan laitteen ja
          liittymän osamaksulla maksaen tuotteet tietyissä kuukausierissä pois.
          Esimerkiksi Elisa tarjoaa tällä hetkellä mobiililaajakaistan ja
          asiakkaan valitseman tabletin alennettuun yhteishintaan. Pakettiin
          sisältyy rajoittamaton tiedonsiirto, jopa 50 Mbit/s
          4G-mobiililaajakaista ilman käyttökattoa ja mahdollisuus sanoa
          liittymäsopimus irti koska tahansa. Tabletin ja liittymän
          yhteiskuukausimaksu on 13,75 euroa.
        </TextArea>
        <Header4>Kampanjat</Header4>
        <TextArea>
          Elisalla liittymissä on myös säännöllisiä ja lyhytkestoisia
          alennuksia. Esimerkiksi nettiliittymät voivat olla alennuksessa yhden
          kuukauden ajan tai erilaiset yksittäiset paketit kuten puhe- ja
          nettipaketit saattavat olla ajoittain alennuksessa. Liittymien ja
          laitteiden alennuksien lisäksi Elisa tarjoaa ajoittain myös ilmaisia
          toimituksia. Myös Elisan muut palvelut sisältävät tarjouksia ja
          ilmaisen kokeilun mahdollisuuksia. Esimerkiksi{" "}
          <a href="https://kirja.elisa.fi/" rel="nofollow">
            Elisa Kirjassa
          </a>
          , eli Elisan ääni- ja e-kirjoja tarjoavassa palvelussa, on
          ajankohtaisia tarjouksia, joista voi tehdä löytöjä jopa -80%
          alennuksella.
        </TextArea>
        <TextArea>
          Elisan eri tuote- ja palvelukategorioiden tarjoukset vaihtuvat usein
          ja voivat olla voimassa vain hyvinkin lyhyen aikaa. Tästä syystä
          kannattaakin ottaa Elisa seurantaan, mikäli haluaa hyödyntää sen
          ajankohtaiset tarjoukset mahdollisimman hyvin ja nopeasti. Elisan
          vaihtuvia tarjouksia voi selata helposti esimerkiksi Elisan
          nettisivuston tarjouksista kertovalla sivulla, mutta ne voi myös
          halutessaan tilata suoraan omaan sähköpostiinsa nettisivustolla olevan
          lomakkeen kautta. Lomakkeen täyttäminen ja lähettäminen käy helposti
          ja sen löytää aivan elisa.fi-sivuston alareunasta.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Elisa Oyj on suomalainen markkinajohtaja ja yksi alan
          edelläkävijöistä, joka tarjoaa monipuolisia ja kilpailukykyisiä
          palveluja ja tuotteita. Elisan tuotteet ovat innovatiivisia ja
          tarjoavat uusimpia tietoliikenneteknologian ominaisuuksia
          asiakkaidensa käyttöön.
        </TextArea>
        <TextArea>
          Elisan tuotteet ja palvelut kattavat useita hintaluokkia ja niiden
          ominaisuuksista löytyvät tarvittavat jokaisen kuluttajan tarpeille.
          Lisäksi Elisa pitää huolta asiakkaistaan monipuolisen asiakaspalvelun
          ja nettipalvelun avulla, sekä tarjoaa kilpailukykyisiä hintoja ja
          hyviä tarjouksia.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(
      filter: { img: { eq: "elisa.jpg" } }
      sort: { fields: price }
    ) {
      nodes {
        benefits
        callPrice
        id
        img
        internetSpeed
        internet
        name
        price
        textPrice
        type
        url
        openFee
      }
    }
  }
`

export default FrontPage
